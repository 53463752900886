<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <Table
      :loading="loading"
      myScheduleOnly
      @loading="value => (loading = value)"
    />
    <v-overlay :value="loading" :z-index="6">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-card>
</template>

<script>
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.my_schedule"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Table: () => import("../Table")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
